@import url(https://fonts.googleapis.com/css2?family=Sacramento&display=swap" rel="stylesheet);
body {
  margin: 0;
  font-family: 'Sacramento', cursive !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rs-container, .rs-header, .rs-content, .rs-footer, .rs-navbar, .rs-nav {
  background: none !important;
}
.rs-nav .rs-nav-item, .rs-nav .rs-dropdown-toggle {
  height: auto !important;
  padding: 18px 22px;
  color: #575757;
  font-size: 24px;
  text-decoration: none;
}
.rs-nav .rs-dropdown-toggle {
  padding-right: 46px !important;
}
.rs-nav .rs-dropdown-toggle-caret {
  top: 20px;
  right: 20px;
}
.navbar-option a {
  display: inline-block !important;
  text-decoration: none !important;
  color: #575757 !important;
  font-size: 24px !important;
}
.navbar-brand a {
  text-shadow: 1px 1px #b3b3b3;
}
@media only screen and (max-width: 480px) {
  .navbar-brand-desktop {
    display: none;
  }
}
@media only screen and (min-width: 480px) {
  .navbar-brand-mobile {
    display: none;
  }
}
.navbar-option a:hover {
  text-decoration: none !important;
  color: #575757 !important;
}
.rs-nav .rs-dropdown {
  padding-top: 7px;
  float: right;
}
.rs-nav-waterline {
  display: none;
}
.rs-content {
  justify-content: center;
}
.page-container {
  padding: 20px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-info {
  display: flex;
  flex-direction: column;
}
.main-info-image {
  display: inline-block;
  width: 550px;
  margin: 0 auto;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1450px) {
  .main-info-image {
    width: 40%;
  }
}
@media only screen and (max-width: 800px) {
  .main-info-image {
    width: 80%;
  }
}
@media only screen and (max-width: 440px) {
  .main-info-image {
    width: 100%;
  }
}
.main-info-title {
  display: flex;
  justify-content: center;
  font-size: 44px;
  margin-bottom: 20px;
  text-align: center;
}
.main-info-sub-title {
  display: flex;
  justify-content: center;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}
.image {
  box-shadow: 3px 3px 5px 0px rgba(87,87,87,0.7);
}
.page-content {
  width: 100%;
  padding: 20px;
  font-size: 22px;
  font-weight: 500;
}
@media only screen and (max-width: 480px) {
  .page-content {
    font-size: 24px;
  }
}
.page-content-image {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 992px) {
  .page-content-image {
    justify-content: flex-end;
  }
}
.page-image {
  max-width: 500px;
  height: auto;
}
@media only screen and (max-width: 1200px) {
  .page-image {
    max-width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .page-image {
    max-height: 900px;
  }
}
@media only screen and (max-width: 600px) {
  .page-image {
    max-height: 500px;
  }
}
@media only screen and (max-width: 480px) {
  .page-image {
    max-height: 350px;
  }
}
.page-content-text {
  max-width: 500px;
}
@media only screen and (max-width: 1200px) {
  .page-content-text  {
    max-width: 100%;
  }
}
.page-content-text-full {
  max-width: 100%;
}
.page-text {
  max-width: 500px;
}
@media only screen and (max-width: 1200px) {
  .page-text {
    max-width: 100%;
  }
}
.page-header {
  text-align: center;
  margin-bottom: 10px;
}
.page-form {
  width: 50%;
  margin: 20px auto;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
}
@media only screen and (max-width: 900px) {
  .page-form {
    width: 100%;
  }
}
.submission-message {
  margin: 20px 0;
}
.details-section {
  margin-bottom: 20px;
}
.sac-header {
  margin: 20px 0;
}
.sac-sub-header {
  margin-bottom: 10px;
}
