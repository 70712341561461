@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap" rel="stylesheet');

body {
  margin: 0;
  font-family: 'Sacramento', cursive !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}